.mc-breadcrumb {
    @include flex-dimension(center, space-between, 0px);

    @include mobile-breakpoint {
        flex-direction: column;
        gap: 15px 0px;
    }
}

.mc-breadcrumb-title {
    text-transform: capitalize;
}

.mc-breadcrumb-list {
    @include flex-dimension(center, start, 0px);
}

.mc-breadcrumb-item {
    font-size: 15px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: var(--textColor);
    @include transition-linear;

    &::after {
        content: "~";
        font-size: 16px;
        margin: 0px 7px;
        color: $green-l;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }
}

.mc-breadcrumb-link {
    color: $green-l;
    @include transition-linear;

    &:hover {
        color: $green-m;
        text-decoration: underline;
    }
}