// custom buttons
.mc-btn {
    @include text-font (13px, 600);
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    padding: 14px 22px;
    letter-spacing: 0.5px;
    border-radius: 8px;
    color: var(--lightColor);
    box-shadow: var(--btnBoxShadow);
    @include transition-linear;

    &:hover {
        opacity: 0.8;
        color: var(--lightColor);
    }

    i {
        font-size: 20px;
        vertical-align: middle;
        margin-#{$end-direction}: 6px;
    }

    span {
        vertical-align: middle;
    }

    &.primary {
        background-color: $violet-d;
    }

    &.yellow {
        background-color: $yellow-500;
    }

    &.purple {
        background-color: $purple-700;
    }

    &.orange {
        background-color: $orange-700;
    }

    &.green {
        background-color: $green-700;
    }

    &.blue {
        background-color: $blue-700;
    }

    &.gray {
        background-color: $gray-700;
    }

    &.red {
        background-color: $red-700;
    }

    &.sm {
        font-size: 11px;
        padding: 12px 18px;

        i {
            font-size: 16px;
        }
    }

    &.h-sm {
        height: 40px;
    }

    &.h-md {
        height: 45px;
    }

    &.h-lg {
        height: 50px;
    }

    &.h-xl {
        height: 55px;
    }
}

// bootstrap buttons
.btn-flexit {
    @include text-font(12px, 500);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding: 12px 22px;
    border-radius: 8px;
    color: var(--lightColor);
    @include transition-linear;

    &:hover {
        color: var(--lightColor);
    }

    &.btn-sm-flexit {
        @include text-font(11px, 500);
        padding: 8px 15px;
    }
}

.btn-filtro-flexit {
    background-color: var(--primaryGreen);
    color: var(--colorSetting);
    border: 1px solid var(--primaryGreen);
}

.btn-filtro-flexit:focus {
    background-color: var(--primaryGreen);
    border: 1px solid var(--primaryGreen);
    color: var(--colorSetting);
}

.btn-filtro-flexit:hover {
    filter: opacity(.8);
    background-color: var(--primaryGreen);
    border: 1px solid white;
    color: var(--colorSetting);
}

.btn-rechazar-flexit {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

.btn-rechazar-flexit:hover {
    filter: opacity(.8);
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

.btn-rechazar-flexit:focus {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

.btn-resetear-flexit {
    background-color: #fff3cd;
    color: #856404;
    border-color: #ffeeba;
}

.btn-resetear-flexit:hover {
    filter: opacity(.8);
    background-color: #fff3cd;
    color: #856404;
    border-color: #ffeeba;
}

.btn-resetear-flexit:focus {
    background-color: #fff3cd;
    color: #856404;
    border-color: #ffeeba;
}