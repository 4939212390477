.mc-user-avatar-upload {
    text-align: center;
}

.mc-user-avatar {
    width: fit-content;
    border-radius: 50%;
    border: 2px solid var(--primaryHEX);
    margin: 0px auto 15px;

    img {
        width: 145px;
        height: 145px;
        object-fit: cover;
        border-radius: 50%;
        border: 3px solid transparent;
    }
}

.mc-user-group {
    padding: 0px 10px 10px;
}

.mc-user-profile {
    margin-bottom: 20px;
    @include flex-dimension(center, start, 10px 20px);
    flex-wrap: wrap;

    h4 {
        text-transform: capitalize;

        span {
            @include text-font(15px, 400);
            text-transform: lowercase;
            margin-top: 5px;
            display: block;
        }
    }
}

.mc-user-metalist {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    li {
        display: flex;
        gap: 15px;

        i { 
            font-size: 20px;
            color: var(--primaryHEX); 
        }

        span { font-size: 15px; }
    }
}

.mc-user-bio {
    font-size: 15px;
}

.mc-user-social {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    a {
        @include flex-dimension(center, center, 5px);
        border-radius: 8px;
        padding: 8px 15px;
        color: var(--lightColor);
        background-color: var(--lightGray);

        i {
            font-size: 15px;
        }

        span {
            @include text-font(14px, 400);
            text-transform: capitalize;
        }

        &.facebook { background-color: var(--facebook); }
        &.twitter { background-color: var(--twitter); }
        &.linkedin { background-color: var(--linkedin); }
        &.youtube { background-color: var(--youtube); }
        &.whatsapp { background-color: var(--whatsapp); }
    }
}

.mc-user-activity-item {
    position: relative;
    margin: 15px 0px;
    padding-#{$start-direction}: 20px;
    border-#{$start-direction}: 1px solid var(--deepBorder);
    @include transition-linear;

    &:hover {
        border-color: var(--primaryHEX);
        &::before { background-color: var(--primaryHEX); }
    }

    &::before {
        content: "";
        @include position-top-left(absolute, 0px, -10px, 1);
        @include content-center(20px, 20px, 50%);
        border: 6px solid var(--lightColor);
        background-color: $gray-500;
        @include transition-linear;
    }
}

.mc-user-activity-title {
    display: flex;
    gap: 10px;

    h6 {
        font-size: 14px;
        text-transform: inherit;
    }

    small {
        font-size: 13px;
        white-space: nowrap;
    }
}

.mc-user-activity-body {
    padding: 15px 0px;
    font-size: 15px;

    .mc-btn {
        margin-top: 20px;
    }
}

.mc-user-activity-media {
    margin-top: 15px;
    @include flex-dimension(center, start, 10px);

    img {
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        border-radius: 8px;
        box-shadow: 0px 3px 10px 0px rgba(var(--blackRGB), 15%);
    }

    h6 { font-size: 14px; }
}