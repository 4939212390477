.mc-main {
    padding: 24px;
    border-radius: 8px;
    background-color: var(--thinGray);
    @include margin(75px, 20px, 20px, 20px);
    @include transition-linear;
    
    @include mobile-breakpoint {
        padding: 24px 15px;
        @include margin(62px, 0px, 0px, 0px);
    }

    &.active {
        margin-#{$start-direction}: 270px;

        @include laptop-breakpoint {
            margin-#{$start-direction}: 20px;
        }

        @include mobile-breakpoint {
            margin-#{$start-direction}: 0px;
        }
    }
}