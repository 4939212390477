.mc-overview {
    background-color: $dark-600;
}

.mc-overview-banner {
    margin-bottom: 120px;
    padding: 150px 0px;
    position: relative;
    text-align: center;
    background-image: url("../images/pages-bg.webp");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 1;

    @include mobile-breakpoint {
        margin-bottom: 80px;
        padding: 80px 20px;
    }

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        @include position-top-left(absolute, 0px, 0px, -1);
        background: linear-gradient(to bottom, rgba($dark-600, 0.85), rgba($blue-900, 0.85));
    }

    .mc-logo-group {
        justify-content: center;
        flex-direction: column;
        margin: 0 auto 42px;

        img { height: 70px; } 

        span {
            background: -webkit-linear-gradient($purple-200, $blue-300);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    h1 {
        color: $blue-200;
        margin-bottom: 10px;
        @include text-font(66px, 800);
        text-transform: capitalize;

        @include mobile-breakpoint {
            font-size: 45px;
        }
    }

    p {
        color: $gray-300;
        font-size: 18px;
        max-width: 700px;
        margin: 0 auto 42px;

        @include mobile-breakpoint {
            font-size: 16px;
        }
    }

    .tools {
        @include flex-dimension(center, center, 20px);
        margin-bottom: 40px;

        @include mobile-breakpoint {
            gap: 15px;
        }

        h3 {
            white-space: nowrap;
            text-transform: capitalize;
            @include text-font(20px, 600);
            color: $gray-400;

            @include mobile-breakpoint {
                font-size: 18px;
            }
        }

        img {
            width: auto;
            height: 40px;

            @include mobile-breakpoint {
                height: 35px;
            }
        }
    }
}

.mc-overview-demo {
    padding: 0px 50px;
    margin-bottom: 120px;

    @include tablet-breakpoint {
        padding: 0px;
    }

    @include mobile-breakpoint {
        margin-bottom: 80px;
    }

    .col {
        padding: 0px 30px;
        margin-top: 50px;

        @include tablet-breakpoint {
            padding: 0px 20px;
            margin-top: 40px;
        }
    }
}

.mc-overview-title {
    text-align: center;
    margin-bottom: 35px;
    letter-spacing: -1px;
    text-transform: capitalize;
    @include text-font(62px, 800);
    background: -webkit-linear-gradient($purple-200, $blue-300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @include mobile-breakpoint {
        font-size: 44px;
    }
}

.mc-overview-footer {
    max-width: 850px;
    margin: 0 auto;
    text-align: center;
    padding: 0px 20px 60px;

    h2 {
        color: $blue-300;
        text-transform: uppercase;
        @include text-font(46px, 900);
        margin-bottom: 40px;

        @include mobile-breakpoint {
            font-size: 32px;
        }
    }

    a {
        margin-bottom: 100px;

        @include mobile-breakpoint {
            margin-bottom: 80px;
        }
    }

    p {
        span {
            color: var(--primaryHEX);
        }
    }
}