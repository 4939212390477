.pFiltro{
    font-size: 12px;
    padding: 2px 6px;
}

.filtros-aplicados-flexit{
    font-size: 12px;
    padding: 2px 6px;
    background-color: var(--primaryGreen);
    border-radius: 10px;
    color:black;
    margin: 0px 3px;
}