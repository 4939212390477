.mc-tabs {
    border: none;
    margin-bottom: 50px;

    @include mobile-breakpoint {
        justify-content: center;
        gap: 5px;
    }

    .nav-link {
        border: none;
        padding: 20px 25px;
        border-radius: 8px;
        @include text-font(15px, 500);
        color: var(--headingColor);
        text-transform: capitalize;
        @include transition-linear;

        &:hover {
            color: var(--lightColor);
            background-color: var(--primaryHEX);
        }

        &.active {
            border: none;
            color: var(--lightColor);
            background-color: var(--primaryHEX);
        }
    }
}

.mc-tabpane {
    padding: 0px 30px 30px;

    @include mobile-breakpoint {
        padding: 0px;
    }

    .mc-icon-field {
        margin-bottom: 0px;
        border-color: var(--deepBorder);
        background-color: var(--thinGray);
        
        &:focus-within { 
            border-color: var(--primaryHEX); 
        }
    }

    &.settings {
        .form-check {
            margin-bottom: 15px;
        }
    }
}
