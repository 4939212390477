.background-dropdown-flexit {
    background-color: var(--paperBG);

    .item-flexit {
        color: var(--colorSetting);

        &:hover {
            background-color: var(--grayDropdown);
        }
    }
}

.mc-dropdown-toggle {
    &.btn {
        border: inherit;
        padding: inherit;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        border-color: inherit;
        letter-spacing: inherit;
        text-transform: inherit;
        background-color: inherit;
        border-radius: inherit;
        line-height: 0px;
        box-shadow: inherit;

        &:hover {
            color: inherit;
        }

        &:focus {
            box-shadow: none;
        }
    }

    &.dropdown-toggle::after {
        display: none;
    }
}

.mc-dropdown-paper {
    padding: 10px;
    border-radius: 8px;
    background-color: var(--paperBG);
    box-shadow: var(--paperBoxShadow);
    border: 1px solid var(--lightBorder);
}

.mc-dropdown-menu {
    width: 100%;
    padding: 7px 8px;
    font-size: 14px;
    border-radius: 6px;
    white-space: nowrap;
    text-transform: capitalize;
    @include flex-dimension(center, start, 0px 5px);
    @include transition-linear;

    &:hover {
        background-color: var(--hoverBG);
    }

    i {
        font-size: 18px;
    }
}